import React from 'react';
import { navigate } from 'gatsby';
import Seo from "../../components/seo"

class TokenEconomyPage extends React.Component {
    componentDidMount = () => {
        navigate('/gettingstarted/create-account');
    }
    
    render = () => <Seo title="Getting Started" />;
};

export default TokenEconomyPage;
